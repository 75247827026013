/**
 * File: analytics.js
 * File Created: Friday, 7th June 2019 11:48:46 am
 * Author: Sacha Cochin (sacha.cochin@gridpocket.com)
 * -----
 * Last Modified: Monday, 1st August 2022 6:04:42 pm
 * Modified By: Paul Mazeau (paul.mazeau@gridpocket.com>)
 * -----
 * GridPocket SAS Copyright (C) 2019 All Rights Reserved
 * This source is property of GridPocket SAS.
 * Please email contact@gridpocket.com for more information.
 */

const ATConfig = {
  '/': {
    name: 'connexion',
    level2: '1'
  },
  '/accueil': {
    name: 'accueil',
    level2: '1'
  },
  '/analyse': {
    name: 'analyse',
    level2: '1'
  },
  '/conseils': {
    name: 'conseils',
    level2: '1'
  },
  '/points': {
    name: 'points',
    level2: '1'
  },
  '/article': {
    name: 'article',
    level2: '1'
  },
  '/profil': {
    name: 'profil'
  },
  '/alertes': {
    name: 'alertes',
    level2: '1'
  },
  '/legal': {
    name: 'legal',
    level2: '1'
  },
  '/cookies': {
    name: 'cookies',
    level2: '1'
  },
  '/profil-energetique': {
    name: 'profilenergetique'
  },
  '/motdepasseperdu': {
    name: 'motdepasseperdu'
  },
  '/lnd-comparaison': {
    name: 'lnd-comparaison',
    level2: '1'
  },
  '/lnd-boutique': {
    name: 'lnd-boutique',
  },
  '/lnd-score': {
    name: 'lnd-score',
  },
  '/admin/users': {
    name: 'dminusers',
    level2: '1'
  },
  '/admin/dashboard': {
    name: 'admindashboard',
    level2: '1'
  },
  '/admin/shop': {
    name: 'adminshop',
    level2: '1'
  },
};
